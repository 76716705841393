{
  "name": "glg.ui",
  "version": "1.6.65",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-stats": "ng build --configuration production --stats-json",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "analyze": "webpack-bundle-analyzer dist/glg.ui/stats.json",
    "e2e": "ng e2e",
    "postinstall": "npx cypress install",
    "cypress:open": "cypress open --config baseUrl=https://app.grocerylistgenerator.local:58881",
    "cypress:run": "cypress run",
    "cypress:run:feature": "cypress run --config baseUrl=https://dev.grocerylistgenerator.com",
    "pwa": "http-server -p 58881 -c-1 --ssl --cert ssl/server.crt --key ssl/server.key dist/glg.ui",
    "bundle-report": "ng build --configuration production --source-map && source-map-explorer dist/**/*.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.5",
    "@angular/cdk": "^19.0.4",
    "@angular/common": "^19.0.5",
    "@angular/compiler": "^19.0.5",
    "@angular/core": "^19.0.5",
    "@angular/forms": "^19.0.5",
    "@angular/material": "^19.0.4",
    "@angular/platform-browser": "^19.0.5",
    "@angular/platform-browser-dynamic": "^19.0.5",
    "@angular/router": "^19.0.5",
    "@angular/service-worker": "^19.0.5",
    "@jsverse/transloco": "^7.5.1",
    "@jsverse/transloco-messageformat": "^7.0.1",
    "angular-auth-oidc-client": "^19.0.0",
    "ngx-quill": "^27.0.0",
    "printd": "^1.6.0",
    "quill": "^2.0.3",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.5",
    "@cypress/schematic": "^2.5.2",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.19.0",
    "@types/jasmine": "~5.1.5",
    "@types/node": "^22.10.4",
    "@typescript-eslint/eslint-plugin": "^8.19.0",
    "@typescript-eslint/parser": "^8.19.0",
    "cypress": "^13.17.0",
    "eslint": "^9.17.0",
    "http-server": "^14.1.1",
    "jasmine-core": "~5.5.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "source-map-explorer": "2.5.3",
    "typescript": "^5.6.3"
  }
}
